// Author: Gary
// no need to make changes, just use it
import _axios from 'axios';

const axios = (baseURL) => {
    const instance = _axios.create({
        baseURL: baseURL || 'https://api.cira.hkrnd.com/',
        timeout: 1000,
        
    });
    return instance;
}

export { axios };
export default axios();