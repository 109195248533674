import { Card, CardContent, Grid, Typography} from '@mui/material';
import { Box, Button, TextField } from '@mui/material';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from '../../components/axios';
import './login.css';
import md5 from 'md5';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'black',
        height: '100vh',
        minHeight: '100%',
    },
    backButton: {
        marginLeft: theme.spacing(2),
    },
    card: {
        overflow: 'visible',
        display: 'flex',
        position: 'relative',
        '& > *': {
            flexGrow: 1,
            flexBasis: '50%',
            width: '50%',
        },
        maxWidth: '475px',
        margin: '24px auto',
    },
    content: {
        padding: theme.spacing(5, 4, 3, 4),
    },
    forgot: {
        textDecoration: 'none',
        paddingLeft: '16px',
    },
    margin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    logo: {
        display: 'block',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf: 'center'
    },
    title: {
        color: theme.palette.text.hint,
    },
    reg_centre_btn: {
        textAlign: 'center',
        paddingTop: '5px !important',
    },
    forgot_pw_btn: {
        textAlign: 'center',
        paddingTop: '20px !important',
    }
}));

//const [errorMessages, setErrorMessages] = useState({});
//const [isSubmitted, setIsSubmitted] = useState(false);
/*
const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { email, password } = document.forms[0];

    // Find user login info
    //const userData = database.find((user) => user.username === uname.value);
    /*
    // Compare user info
    if (userData) {
        if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
    } else {
        setIsSubmitted(true);
    }
    } else {
        // Username not found
        setErrorMessages({ name: "uname", message: errors.uname });
    }
    
};
*/

const renderErrorMessage = (name) =>(0
    //name === errorMessages.name && (
        //<div className="error">{errorMessages.message}</div>
);
/*
async function loginUser(credentials) {
    return fetch('http://localhost:8080/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
      .then(data => data.json())
}
*/

async function loginUser(credentials) {
    // connect to backend for check the login account
    console.log(credentials);
    console.log(JSON.stringify(credentials));
    var passToken = false;
    try{
        await axios.post(
            '/static/login.php', JSON.stringify(credentials)
        ).then(
            result => {
                console.log(result.data);
                if (result.data[0].id == "No Account"){
                    window.alert(result.data[0].id);                    
                } else if (result.data[0].id == "Wrong Password"){
                    window.alert(result.data[0].id);
                }
                else {
                    window.alert('successful login');
                    localStorage.setItem('userLoginToken', result.data[0].count); // number of user's device
                    localStorage.setItem('device', result.data[0].device); // user's device_id
                    passToken = result.data[0].id;
                }
            }
        )
    } catch (error){
        console.log(error);
        return false;
    }

    // return token if true email and password
    return passToken;
}

async function userRegister(credentials) {
    // connect to backend for register account
    console.log(credentials);
    console.log(JSON.stringify(credentials));
    var registered = false;
    try{
        await axios.post(
            '/static/user_register.php', JSON.stringify(credentials)
        ).then(
            result => {
                console.log(result.data);
                if (result.data == "Register Complete"){
                    window.alert(result.data[0]);
                    registered = true;
                }
                else {
                    console.log(result.data);
                    window.alert(result.data[0]);
                    registered = false;
                }
            }
        )
    } catch (error){
        console.log(error);
    }

    // return token if true email and password
    return registered;
}

function Login({ setToken}) {
    const [register, setRegister] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [name, setName] = useState();
    console.log(email);
    console.log(password);
    console.log(name);
    if (password != null){
        console.log(md5(password));
        var encryptPassword = md5(password);
    }
    

    // pass email and password for login
    const handleLogin = async e => {
        e.preventDefault();
        const token = await loginUser({
            email,
            encryptPassword
        });
        if (token) {
            // go to home page after login
            window.location.replace("/home");
        }
        setToken(token);
    }

    // pass email and password for register account
    const handleRegister = async e => {
        e.preventDefault();
        const registered = await userRegister({
            name,
            email,
            encryptPassword
        });
        setRegister(!registered);
    }

    // clear input when change page
    const switchPage = () => {
        setRegister(!register);
        setName('');
        setEmail('');
        setName('');
        if (register)
            document.getElementById('name').value = '';
        document.getElementById('email').value = '';
        document.getElementById('password').value = '';
    }

    const classes = useStyles();

    if (!register){
        return (
            <Grid container justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item xs={11} sm={7} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.content}>
                            <Grid container direction="column" spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography variant="h4" align="center" className={classes.title}>
                                        CIRA
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" align="center" className={classes.title}>
                                        Login
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center" alignItems="center">
                                
                                <form onSubmit={handleLogin}>
                                    <Grid container rowSpacing={2} columnSpacing={2} direction="row" justifyContent="center" alignItems="center">
                                        <Grid item xs={12} align="center">
                                            <TextField type="text" id="email" label="Username" variant="outlined" onChange={e => setEmail(e.target.value)} required />
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <TextField type="password" id="password" label="Password" variant="outlined" onChange={e => setPassword(e.target.value)} required />
                                        </Grid>
                                            
                                            <Grid item xs={3}>
                                                <Button variant='contained' type="submit" >Login</Button>
                                            </Grid>
                                            {/*
                                            <Grid item xs={7}>
                                                <Button variant='contained' type="button" onClick={switchPage}>Create Account</Button>
                                            </Grid>
                                            */}
                                            
                                    </Grid>
                                </form>
                                    
                                </Grid>
                                <Grid item xs={12} className={classes.forgot_pw_btn}>
                                    {/*<RouterLink to="/password/forgotPassword">Forgot Password</RouterLink>*/}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
    else {
        return (
            <Grid container justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item xs={11} sm={7} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.content}>
                            <Grid container direction="column" spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography variant="h4" align="center" className={classes.title}>
                                        CIRA
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" align="center" className={classes.title}>
                                        Register
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center" alignItems="center">
                                
                                <form onSubmit={handleRegister}>
                                    <Grid container rowSpacing={2} columnSpacing={2} direction="row" justifyContent="center" alignItems="center">
                                        <Grid item xs={12} align="center">
                                            <TextField type="text" id="name" label="Name" variant="outlined" onChange={e => setName(e.target.value)} required />
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <TextField type="text" id="email" label="Username" variant="outlined" onChange={e => setEmail(e.target.value)} required />
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <TextField type="password" id="password" label="Password" variant="outlined" onChange={e => setPassword(e.target.value)} required />
                                        </Grid>
                                            <Grid item xs={12} align="center">
                                                <Button variant='contained' type="submit" >Create Account</Button>
                                            </Grid>
                                            <Grid item xs={12} align="center">
                                                <Button variant='contained' type="button" onClick={switchPage}>Already have Account? Login</Button>
                                            </Grid>
                                    </Grid>
                                </form>
                                </Grid>
                                <Grid item xs={12} className={classes.forgot_pw_btn}>
                                    {/*<RouterLink to="/password/forgotPassword">Forgot Password</RouterLink>*/}
                                </Grid>
                                <Grid item xs={12} className={classes.reg_centre_btn}>
                                    {/*<RouterLink to="/register">Register</RouterLink>*/}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }

    

}

export default Login;

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
