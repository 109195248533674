import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Box, Button, TextField } from '@mui/material';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './login.css';
import md5 from 'md5';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'black',
        height: '100vh',
        minHeight: '100%',
    },
    backButton: {
        marginLeft: theme.spacing(2),
    },
    card: {
        overflow: 'visible',
        display: 'flex',
        position: 'relative',
        '& > *': {
            flexGrow: 1,
            flexBasis: '50%',
            width: '50%',
        },
        maxWidth: '475px',
        margin: '24px auto',
    },
    content: {
        padding: theme.spacing(5, 4, 3, 4),
    },
    forgot: {
        textDecoration: 'none',
        paddingLeft: '16px',
    },
    margin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    logo: {
        display: 'block',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf: 'center'
    },
    title: {
        color: theme.palette.text.hint,
    },
    reg_centre_btn: {
        textAlign: 'center',
        paddingTop: '5px !important',
    },
    forgot_pw_btn: {
        textAlign: 'center',
        paddingTop: '20px !important',
    }
}));

function Logout({ setToken}) {
    
    // logout
    const handleLogout = async e => {
        localStorage.removeItem('token');
        localStorage.removeItem('userLoginToken');
        localStorage.removeItem('device');
        setToken(false);
        window.location.replace("/home");
    }

    const classes = useStyles();

        return (
            <Grid container justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item xs={11} sm={7} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.content}>
                            <Grid container direction="column" spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            {/* <RouterLink to="/" className={classes.icon}>
                                                <img alt="Loco" src={Logo} className={classes.logo} />
                                            </RouterLink> */}
                                            <Typography variant="h4" align="center" className={classes.title}>
                                                CIRA
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" align="center" className={classes.title}>
                                        Logout
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center" alignItems="center">
                                
                                <form onSubmit={handleLogout}>
                                    <Grid container rowSpacing={2} columnSpacing={2} direction="row" justifyContent="center" alignItems="center">
                                        <Grid item xs={12} align="center">
                                            Are You sure Logout?
                                        </Grid>
                                            <Grid item xs={6} align="center">
                                                <Button variant='contained' type="submit" >Yes</Button>
                                            </Grid>
                                            <Grid item xs={6} align="center">
                                                <Button variant='contained' href='/home'>No</Button>
                                            </Grid>
                                    </Grid>                                
                                </form>
                                    
                                </Grid>
                                <Grid item xs={12} className={classes.forgot_pw_btn}>
                                    {/*<RouterLink to="/password/forgotPassword">Forgot Password</RouterLink>*/}
                                </Grid>
                                <Grid item xs={12} className={classes.reg_centre_btn}>
                                    {/*<RouterLink to="/register">Register</RouterLink>*/}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
      


export default Logout;

Logout.propTypes = {
    setToken: PropTypes.func.isRequired
}
